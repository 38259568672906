<!--
 * @Author: yy
 * @Date: 2021-09-29 11:13:32
 * @LastEditTime: 2021-10-11 20:12:11
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\academicArchives\recordList.vue
-->
<template>
    <el-container>
        <el-header>
            <public-header :leftArrow="true">自适应测试记录</public-header>
        </el-header>
        <el-main>
            <v-card class="rounded-xl">
                <loadMore ref="more" @query="queryList" :list.sync="recordList" path="academicArchives">
                    <recordTables :list="recordList" />
                </loadMore>
             </v-card>
        </el-main>
    </el-container>
</template>
<script>
import recordTables from "@/components/academicArchives/recordTables";
import loadMore from "@/components/loadMore/loadMore";

export default {
    components: {
        recordTables,
        loadMore
    },
    data: () => ({
        recordList:[],
    }),
    methods:{
        queryList(pageNum,pageSize){
            this.$service.getUserCatRecord({
                pageNum,
                pageSize
            }).then(res => {
                let arr = res.catRecords;
                this.$refs.more.complete(arr,res.count);
            });
        }    
    }
}
</script> 
<style lang="scss" scoped>
.rounded-xl{
    max-height: calc(100vh - 100px);
    overflow:hidden;
}
</style> 

